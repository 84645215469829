import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { defineMessages } from 'react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import Popover from '@alltrails/shared/components/Popover';
import Overflow from '@alltrails/denali/icons/Overflow';
import IconButtonWithTooltip from '@alltrails/denali/components/IconButtonWithTooltip';
import LinkButtonOrDiv from '@alltrails/denali/components/LinkButtonOrDiv';
import { UserConnection, UserConnectionActionType, UserConnectionListKey } from '@alltrails/shared/types/belay';
import SuccessToast from '@alltrails/shared/types/successToast';
import ConnectButton from '@alltrails/modules/UserConnections/ConnectButton';
import styles from './styles/styles.module.scss';

const messages = defineMessages({
  REPORT_AN_ISSUE: { defaultMessage: 'Report an issue' },
  MORE_OPTIONS: { defaultMessage: 'More options' },
  RESPOND: { defaultMessage: 'Respond' },
  DELETE: { defaultMessage: 'Delete' },
  EDIT: { defaultMessage: 'Edit' },
  ADMIN: { defaultMessage: 'ADMIN' },
  EXCLUDE_FROM_SUMMARY: { defaultMessage: 'Exclude from summary' },
  ADD_TO_SUMMARY: { defaultMessage: 'Add to summary' }
});

export type TrailReviewOverflowMenuProps = {
  isThirdPartyReview: boolean;
  isMoreOptionsOpen: boolean;
  setIsMoreOptionsOpen: Dispatch<SetStateAction<boolean>>;
  reviewConnection?: UserConnection;
  onConnectClick: (actionType: UserConnectionActionType) => void;
  setIsReportingModalOpen: Dispatch<SetStateAction<boolean>>;
  canComment?: boolean;
  isEditable?: boolean;
  isAdmin?: boolean;
  hideReplies?: boolean;
  onCommentClick?: () => void;
  onDeleteClick?: () => void;
  onEditClick?: () => void;
  setReportingSuccessToast?: Dispatch<SetStateAction<SuccessToast>>;
  summaryExclusion?: boolean;
  onToggleSummaryExclusion?: (summaryExclusion: boolean) => void;
};

const TrailReviewOverflowMenu = ({
  isThirdPartyReview,
  isMoreOptionsOpen,
  setIsMoreOptionsOpen,
  reviewConnection,
  onConnectClick,
  setIsReportingModalOpen,
  canComment,
  isEditable,
  isAdmin,
  hideReplies,
  onCommentClick,
  onDeleteClick,
  onEditClick,
  setReportingSuccessToast,
  summaryExclusion,
  onToggleSummaryExclusion
}: TrailReviewOverflowMenuProps): JSX.Element => {
  const {
    formattedDefaultMessages: { REPORT_AN_ISSUE, MORE_OPTIONS, RESPOND, DELETE, EDIT, ADMIN, EXCLUDE_FROM_SUMMARY, ADD_TO_SUMMARY }
  } = useFormatMessage(messages);

  const blockActionType = useMemo(() => {
    const actionsTypes = reviewConnection?.actions ? (Object.keys(reviewConnection?.actions) as UserConnectionActionType[]) : [];
    return [UserConnectionActionType.Unblock, UserConnectionActionType.Block].find(action => actionsTypes.includes(action));
  }, [reviewConnection]);

  const isUserBlocked = useMemo(() => {
    const actionsTypes = reviewConnection?.actions ? (Object.keys(reviewConnection?.actions) as UserConnectionActionType[]) : [];
    return !!actionsTypes.find(action => action === UserConnectionActionType.Unblock);
  }, [reviewConnection]);

  const onClickRemoveFromSummary = useCallback(() => {
    onToggleSummaryExclusion?.(true);
    setIsMoreOptionsOpen(false);
  }, [onToggleSummaryExclusion, setIsMoreOptionsOpen]);

  const onClickAddToSummary = useCallback(() => {
    onToggleSummaryExclusion?.(false);
    setIsMoreOptionsOpen(false);
  }, [onToggleSummaryExclusion, setIsMoreOptionsOpen]);

  const handleEditClick = useCallback(() => {
    onEditClick?.();
    setIsMoreOptionsOpen(false);
  }, [onEditClick, setIsMoreOptionsOpen]);

  return (
    <Popover
      alignment="bottom-right"
      isOpen={isMoreOptionsOpen}
      popperClassName={styles.popover}
      ignoreContentClick
      verticalOffset={8}
      horizontalOffset={0}
      onCloseRequest={() => setIsMoreOptionsOpen(false)}
      anchorElement={
        <div>
          <IconButtonWithTooltip
            icon={{ Component: Overflow }}
            onClick={() => setIsMoreOptionsOpen(!isMoreOptionsOpen)}
            testId="more-options"
            title={MORE_OPTIONS}
            variant="flat"
            size="sm"
          />
        </div>
      }
    >
      <div className={styles.menuList}>
        {isThirdPartyReview && (
          <div className={styles.thirdPartyReview}>
            {reviewConnection && !isUserBlocked && (
              <div className={styles.menuItem}>
                <ConnectButton
                  userConnection={reviewConnection}
                  listKey={UserConnectionListKey.TrailReview}
                  onClick={onConnectClick}
                  className={styles.connectItem}
                  stopPropagation
                  setSuccessToast={setReportingSuccessToast}
                />
              </div>
            )}
            {reviewConnection && (
              <div className={styles.menuItem}>
                <ConnectButton
                  onClick={onConnectClick}
                  actionType={blockActionType}
                  userConnection={reviewConnection}
                  stopPropagation
                  className={styles.connectItem}
                  setSuccessToast={setReportingSuccessToast}
                />
              </div>
            )}
            <LinkButtonOrDiv className={styles.menuItem} onClick={() => setIsReportingModalOpen(true)} testId="review-report-an-issue-button">
              {REPORT_AN_ISSUE}
            </LinkButtonOrDiv>
          </div>
        )}
        {isEditable && (
          <div className={styles.adminOptions}>
            <div className={styles.divider} />
            {isAdmin && <div className={styles.adminItem}>{ADMIN}</div>}
            <LinkButtonOrDiv className={styles.menuItem} testId="deleteReview" onClick={onDeleteClick} key={DELETE}>
              <span> {DELETE} </span>
            </LinkButtonOrDiv>
            {!hideReplies && canComment ? (
              <LinkButtonOrDiv className={styles.menuItem} testId="commentReview" onClick={onCommentClick} key={RESPOND}>
                <span> {RESPOND} </span>
              </LinkButtonOrDiv>
            ) : (
              !isThirdPartyReview && (
                <LinkButtonOrDiv className={styles.menuItem} testId="editReview" onClick={handleEditClick} key={EDIT}>
                  <span> {EDIT} </span>
                </LinkButtonOrDiv>
              )
            )}
            {isAdmin && !summaryExclusion && (
              <LinkButtonOrDiv
                className={styles.menuItem}
                testId="excludeReviewFromSummary"
                onClick={onClickRemoveFromSummary}
                key="Exclude from summary"
              >
                <span> {EXCLUDE_FROM_SUMMARY} </span>
              </LinkButtonOrDiv>
            )}
            {isAdmin && summaryExclusion && (
              <LinkButtonOrDiv className={styles.menuItem} testId="addReviewToSummary" onClick={onClickAddToSummary} key="Add to summary">
                <span> {ADD_TO_SUMMARY} </span>
              </LinkButtonOrDiv>
            )}
          </div>
        )}
      </div>
    </Popover>
  );
};

export default TrailReviewOverflowMenu;
