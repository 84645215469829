import { useState } from 'react';
import classNames from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import styles from './styles/styles.module.scss';

// https://www.figma.com/file/9Y1n3VoMJAcTGPe82bvySj/Denali-Components?type=design&node-id=4-3435&mode=design&t=Yxs1kHbc7Xboqaut-0

type TooltipProps = {
  anchorElement: JSX.Element;
  anchorContainerClassName?: string;
  containerClassName?: string;
  maxWidth?: number;
  popperClassName?: string;
  hidePointer?: boolean;
  testId?: string;
  text: string;
  tooltipPlacement?: 'top' | 'bottom' | 'right' | 'left';
};

export default function Tooltip({
  anchorElement,
  anchorContainerClassName,
  containerClassName,
  maxWidth,
  popperClassName,
  hidePointer = false,
  testId,
  text,
  tooltipPlacement
}: TooltipProps) {
  const tooltipMaxWidth = maxWidth ? Math.min(maxWidth, 240) : 'none';
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const tooltipId = `${testId}-tooltip`;

  return (
    <div className={containerClassName}>
      <div
        data-tooltip-id={tooltipId}
        // Allow focusing
        tabIndex={0}
        aria-describedby={`${testId}-tooltip`}
        data-testid={testId}
        className={anchorContainerClassName}
      >
        {anchorElement}
      </div>
      {/* This allows the tooltip text to be visible to screenreaders when the tooltip isn't in the DOM: */}
      <div hidden id={isTooltipShown ? '' : tooltipId}>
        {text}
      </div>
      <ReactTooltip
        id={tooltipId}
        delayShow={300}
        float={hidePointer}
        place={!maxWidth ? undefined : tooltipPlacement}
        globalCloseEvents={{ escape: true }}
        className={classNames(styles.popper, popperClassName)}
        classNameArrow={styles.arrow}
        style={{ maxWidth: tooltipMaxWidth }}
        noArrow={hidePointer}
        opacity={1}
        afterShow={() => setIsTooltipShown(true)}
        afterHide={() => setIsTooltipShown(false)}
      >
        {text}
      </ReactTooltip>
    </div>
  );
}
