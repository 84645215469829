import { useState } from 'react';
import { useIntl } from 'react-intl';
import { PhotoSize } from '@alltrails/shared/types/photos';
import ReviewPhoto from '@alltrails/shared/types/ReviewPhoto';
import { getPhotoUrl } from '@alltrails/shared/utils/requests/photoRequests';
import { Image } from '@alltrails/core';
import PhotoSlash from '@alltrails/denali/icons/PhotoSlash';
import styles from './styles/styles.module.scss';

type TrailReviewPhotoProps = {
  idx: number;
  onPhotoClick: (photo: ReviewPhoto, idx: number, ev: React.MouseEvent<HTMLButtonElement>) => void;
  onPhotoFocus?: (ev: React.FocusEvent<HTMLButtonElement>) => void;
  photo: ReviewPhoto;
  photoCount: number;
};

const TrailReviewPhoto = ({ idx, onPhotoClick, onPhotoFocus, photo, photoCount }: TrailReviewPhotoProps) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const src = getPhotoUrl(photo, PhotoSize.LargeSquare);

  const onClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (error) {
      new (Image as any)().src = src; // retry loading the image
      setError(false);
    } else {
      onPhotoClick(photo, idx, ev);
    }
  };

  return (
    <button
      aria-label={intl.formatMessage({ defaultMessage: 'View photo {idx} of {photoCount}' }, { idx, photoCount })}
      className={styles.photoInteractive}
      disabled={loading}
      onClick={onClick}
      onFocus={onPhotoFocus}
      type="button"
    >
      {error && (
        <div className={styles.errorBackground} data-testid={`review-photo-error-${photo.id}`}>
          <div className={styles.errorIconContainer}>
            <PhotoSlash color="--color-text-secondary" size="md" />
          </div>
        </div>
      )}
      {!error && (
        <Image
          alt={photo.title || intl.formatMessage({ defaultMessage: 'Photo {idx} of {photoCount}' }, { idx, photoCount })}
          className={styles.photo}
          loading="lazy"
          onError={() => setError(true)}
          onLoad={() => setLoading(false)}
          src={src}
          testId={`review-photo-${photo.id}`}
          width={112}
          height={112}
          handleLoadingState
        />
      )}
    </button>
  );
};

export default TrailReviewPhoto;
