import { ComponentProps, useMemo } from 'react';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react'; // If FocusTrap is used for other "full-screen" UIs, consider splitting the overlay + FocusTrap into its own component
import { ModalPortal, useScreenLock } from '@alltrails/core';
import IconRenderer, { type IconDefinition } from '../IconRenderer';
import Button from '../Button';
import type { Size } from '../../types';
import styles from './styles/styles.module.scss';

export type ButtonDefinition = Pick<ComponentProps<typeof Button>, 'icon' | 'onClick' | 'testId' | 'text' | 'loading' | 'variant'>;
type ButtonVariant = ComponentProps<typeof Button>['variant'];

type AlertDialogProps = {
  body?: string;
  buttons:
    | { primary: ButtonDefinition; flat?: ButtonDefinition; default?: never }
    | { primary: ButtonDefinition; flat: ButtonDefinition; default: ButtonDefinition }
    | { default: ButtonDefinition; primary?: never; flat?: never };
  className?: string;
  icon?: IconDefinition<'color' | 'orientation'>;
  iconBackground?: boolean;
  onRequestClose: () => void;
  size: Size<'sm' | 'md' | 'lg'>;
  title: string;
  dimensionsTheme?: 'default' | 'compact';
};

export default function AlertDialog({
  body,
  buttons,
  className,
  icon,
  iconBackground,
  onRequestClose,
  size,
  title,
  dimensionsTheme
}: AlertDialogProps) {
  useScreenLock(true);

  const hasFlatButton = (buttons: AlertDialogProps['buttons']): buttons is { primary: ButtonDefinition; flat?: ButtonDefinition; default?: never } =>
    'flat' in buttons;

  const buttonDefinitions: { variant: ButtonVariant; button?: ButtonDefinition }[] = [
    { variant: buttons.primary?.variant || 'primary', button: buttons.primary },
    { variant: 'default', button: buttons.default },
    { variant: 'flat', button: hasFlatButton(buttons) ? buttons.flat : undefined }
  ];

  const renderedButtons = buttonDefinitions
    .filter(({ button }) => !!button)
    .map(({ variant, button }) => (
      <Button
        key={`alert-dialog-${variant}-button`}
        {...(button as ButtonDefinition)}
        variant={variant}
        width={size === 'sm' ? 'full' : 'fullOnMobile'}
      />
    ));

  return (
    <ModalPortal>
      {/* The modal portal is rendered outside of the div, so it is necessary to add theming here */}
      <div className={styles.overlay} data-testid="alert-dialog-overlay" id="alertDialog" data-at-theme-dimensions={dimensionsTheme}>
        <FocusTrap
          active
          // fallbackFocus is used to prevent errors in testing https://github.com/focus-trap/focus-trap-react/issues/91
          focusTrapOptions={{
            clickOutsideDeactivates: true,
            escapeDeactivates: true,
            onDeactivate: onRequestClose,
            fallbackFocus: '#alert-dialog',
            initialFocus: false
          }}
        >
          <div
            className={classNames(className, styles.dialog, styles[size])}
            id="alert-dialog"
            role="alertdialog"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-body"
          >
            <div className={styles.iconAndText}>
              {icon && (
                <div className={classNames(styles.icon, { [styles.iconBackground]: iconBackground })}>
                  <IconRenderer icon={icon} defaults={{ size: 'lg' }} />
                </div>
              )}
              <div className={styles.text}>
                <div className={styles.title} id="alert-dialog-title">
                  {title}
                </div>
                {body && (
                  <div className={styles.body} id="alert-dialog-body">
                    {body}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.buttons}>{renderedButtons}</div>
          </div>
        </FocusTrap>
      </div>
    </ModalPortal>
  );
}
